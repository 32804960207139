.form-group-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.form-group-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: space-between;
}
.form-group-root-class-name {
  width: 100%;
}
@media(max-width: 479px) {
  .form-group-container1 {
    flex-direction: column;
  }
  .form-group-root-class-name {
    width: 100%;
  }
}
