.don-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  padding-top: 35px;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
}
.don-image {
  width: auto;
  object-fit: cover;
}
.don-text {
  color: #0059a5;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
}
.don-text01 {
  color: rgb(35, 31, 32);
  font-size: 20px;
  text-align: center;
  padding-bottom: 50px;
}
.don-container1 {
  flex: 0 0 auto;
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 36px;
  flex-direction: column;
  padding-bottom: 47px;
  justify-content: center;
  background-color: #F4EBFF;
}
.don-image1 {
  width: auto;
  object-fit: cover;
  padding-bottom: 11px;
}
.don-text05 {
  color: rgb(106, 84, 142);
  font-size: 28px;
  align-self: center;
  text-align: center;
  font-weight: 700;
  padding-bottom: 20px;
}
.don-text06 {
  font-style: normal;
}
.don-text07 {
  font-style: normal;
}
.don-text08 {
  font-style: normal;
}
.don-text09 {
  font-style: normal;
}
.don-text10 {
  font-style: normal;
}
.don-text11 {
  color: #6a548e;
  font-size: 20px;
  font-weight: 600;
}
.don-text12 {
  color: #6a548e;
  font-size: 26px;
  font-weight: 600;
}
.don-text13 {
  color: #6a548e;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}
.don-link {
  color: #ffffff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  padding-top: 10px;
  border-color: #6A548E;
  border-width: 1px;
  padding-left: 20px;
  border-radius: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: rgb(106, 84, 142);
}
@media(max-width: 1200px) {
  .don-container1 {
    width: 60%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media(max-width: 991px) {
  .don-container1 {
    width: 60%;
  }
}
@media(max-width: 767px) {
  .don-text01 {
    color: rgb(35, 31, 32);
    font-size: 20px;
  }
  .don-container1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .don-text {
    text-align: center;
  }
  .don-container1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
