.form-input-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.form-input-container1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: center;
}
.form-input-textinput {
  color: #231f20;
  font-size: 16px;
  align-self: stretch;
  padding-top: 13px;
  border-color: #707070;
  padding-left: 25px;
  border-radius: 10px;
  padding-bottom: 13px;
}
.form-input-textinput:focus{
  outline: none;
}

.form-input-text {
  color: var(--dl-color-danger-700);
  width: 100%;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  padding-left: 25px;
}





.form-input-root-class-name5 {
  width: 28%;
}
.form-input-root-class-name6 {
  width: 70%;
}
@media(max-width: 479px) {
  .form-input-root-class-name5 {
    width: 100%;
  }
  .form-input-root-class-name6 {
    width: 100%;
  }
}
