.modify-invitation-container,.formModif div:not(.actionUpdate){
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 10px;
  border-color: #707070;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 10px;
  padding-right: 34px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
}

.formModif div input:focus{
  outline: none;
}

.formModif div:not(.actionUpdate),.formModif div input:not(.validateModif){
  background-color: #E8F0FE;
}

.formModif div:not(.actionUpdate){
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.actionUpdate{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 20px;
}

.actionUpdate p{
  color: #0059A5;
  font-size: 16px;
}

.modify-invitation-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.modify-invitation-text {
  color: #231f20;
  font-size: 16px;
}
.modify-invitation-container2 {
  gap: 20px;
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.modify-invitation-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.modify-invitation-image {
  width: auto;
  object-fit: cover;
}
.modify-invitation-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modify-invitation-image1 {
  width: auto;
  object-fit: cover;
}
.formModif{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.validateModif{
  color: #ffffff;
  font-size: 16px;
  align-self: center;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 15px;
  border-color: #0059a5;
  margin-right: 0px;
  padding-left: 21px;
  border-radius: 10px;
  margin-bottom: 0px;
  padding-right: 21px;
  padding-bottom: 15px;
  background-color: #0059a5;
}


