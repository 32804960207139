.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  padding-top: 10px;
  flex-direction: column;
  padding-bottom: 60px;
  justify-content: flex-start;
}
.home-image {
  object-fit: cover;
  padding-top: 0px;
}
.home-image1 {
  width: 200px;
  display: none;
  object-fit: cover;
}
.home-container01 {
  flex: 0 0 auto;
  width: 74%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: 0px;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: center;
}
.home-container02 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container03 {
  flex: initial;
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-text {
  color: #0059a5;
  font-size: 20px;
  text-align: left;
  padding-bottom: 25px;
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container05 {
  flex: 0 0 auto;
  width: 80%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  border-color: #0059A5;
  border-style: dashed;
  margin-bottom: 25px;
  padding-bottom: 0px;
  background-color: #0059A5;
}
.home-form {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#tooLate{
  font-size: 25px;
  text-align: center;
}

.civilityContainer{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.civilityChoice{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  gap: 5px;
}

.home-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: 0px;
  margin-right: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-button {
  align-self: center;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.home-text1 {
  color: rgb(0, 89, 165);
  font-size: 16px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  text-decoration: underline;
}
.home-textinput {
  color: #ffffff;
  font-size: 16px;
  align-self: center;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 15px;
  border-color: #0059a5;
  margin-right: 0px;
  padding-left: 21px;
  border-radius: 10px;
  margin-bottom: 0px;
  padding-right: 21px;
  padding-bottom: 15px;
  background-color: #0059a5;
}
.home-container09 {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image2 {
  width: 100%;
  object-fit: cover;
}
@media(max-width: 1600px) {
  .home-container01 {
    width: 89%;
  }
  .home-container03 {
    width: 70%;
  }
  .home-container07 {
    width: 100%;
  }
}
@media(max-width: 1200px) {
  .home-image {
    width: 100%;
  }
  .home-container02 {
    width: 100%;
  }
  .home-container03 {
    width: 70%;
  }
  .home-container07 {
    width: 100%;
  }
  .home-container09 {
    display: none;
  }
}
@media(max-width: 991px) {
  .home-container03 {
    width: 70%;
  }
  .home-form {
    width: 100%;
  }
  .home-container07 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-container03 {
    width: 70%;
  }
  .home-container07 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-container {
    padding-top: 0px;
  }
  .home-image {
    display: none;
  }
  .home-image1 {
    width: 100%;
    display: block;
  }
  .home-container03 {
    width: 90%;
  }
  .home-form {
    width: 100%;
  }
  .home-container06 {
    align-items: center;
  }
  .home-container07 {
    width: 100%;
  }
  .home-container08 {
    padding-top: 15px;
    flex-direction: column;
    padding-bottom: 15px;
  }
  .home-text1 {
    padding-bottom: 40px;
  }
}
